import { Home } from '@/api/index'

export async function getOneNews(data) {
    let res = await Home.getOneNews(data)
    return res
}

export async function queryNewsList(data) {
    let res = await Home.queryNewsList(data)
    return res;
}