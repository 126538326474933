<template>
  <div class="w news-list">
    <div v-for="(item, index) in newsList" :key="index" @click="newDetail(item.owid)">
      <div class="navList">
        <img src="@/assets/img/logo.png" class="img" />
        <div class="navAll">
            <div class="wzbt">{{ item.wzbt }}</div>
            <div class="content">{{ item.jjnr }}</div>
            <div class="fbr">
              <div class="">{{ item.fbr }}</div>
              <div class="sxsj">{{ item.sxsj }}</div>
            </div>
            
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryNewsList } from "./model";
export default {
  data() {
    return {
      reqParams: {
        pageNo: 1,
        pageSize: 4,
        zxlb: "001",
      },
      newsList: "",
      newsInfo: "",
    };
  },
  created() {
    queryNewsList(this.reqParams).then((res) => {
      this.newsList = res.records;
    });
  },
  methods: {
    newDetail(owid) {
      this.$router.push({ path: "/newDetail", query: { owid: owid } });
    },
  },
};
</script>
<style scoped>
.navList {
  display: flex;
  /* border: 1px solid #999; */
  padding: 20px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.img {
  width: 200px;
  height: 100px;
  /* border: 1px solid red; */
}
.navAll {
  display: block;
  flex: 1;
}
.wzbt {
  /* text-align: left; */
  padding-left: 20px;
  font-size: 24px;
}
.sxsj {
  text-align: right;
  margin-left: 20px;
  /* width: 1.5rem;
  padding-right: 1em; */
}
.fbr{
  display: flex;
  justify-content: end;
  font-size: 18px;
}
.content{
  padding: 10px 0px 10px 20px;
  font-size: 20px;
  height: 50px;
}
</style>